/* eslint-disable no-use-before-define */
import axios from 'axios'

export interface IOSMAddress {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
  place_rank: number;
  category: string;
  type: string;
  importance: number;
  addresstype: string;
  name: string;
  display_name: string;
  address: Address;
  boundingbox?: string[] | null;
}

export interface Address {
  road: string;
  city: string;
  county: string;
  state: string;
  ISO3166_2_lvl4: string;
  postcode: string;
  country: string;
  country_code: string;
}

export default async function (lat: string | number, lng: string | number) {
  const { data } = await axios.get<IOSMAddress>(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`, {
    timeout: 5000
  })
  return data
}
