/* eslint-disable @typescript-eslint/no-empty-function */

export async function trycatch (
  func: () => any,
  fail: any,
  { onError = undefined }: { onError?: (e: any) => void } = {}
) {
  try {
    return await func();
  } catch (e) {
    if (onError) onError(e);
    return fail;
  }
}

export function trycatchSync (func: () => any, fail: any) {
  try {
    return func();
  } catch (e) {
    return fail;
  }
}
