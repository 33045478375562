/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import formatLuxonDate from '@/utils/formatLuxonDate';
import { ReportDestinationData, ReportItem, ReportItemConfig, ReportItemPayload } from '@/store/interfaces/ReportModules'
import Timer from 'easytimer.js'
import store from '@/store/index'
import getReportItemConfig from '@/store/modules/reports/getReportItemConfig'
import Driver from '@/store/interfaces/Driver'
import format from 'string-template'
import { PLATAFORM_TYPE } from '@/store/catalogs/PLATAFORM_TYPE'
import { CONSOLE_NOTIFICATIONS } from '@/store/catalogs/CONSOLE_NOTIFICATIONS'
import { Sensors } from '@/store/interfaces/Sensors'
import { ICommand } from '@/store/interfaces/Command'
import { Geofence } from '@/store/interfaces/Geofences'
import isOnGeofence from '@/utils/isOnGeofence'
import { RouteDestination } from '@/store/modules/geofences'
import getSecondsDiffFromNow from '@/utils/getSecondsDiffFromNow'
import timeFormating from '@/utils/timeFormating'
import { extract } from '@/utils/getKeysByStringBrackets'
import { serializeError } from 'serialize-error'
import { trycatch } from '@/utils/trycatch'
import { DateTime, Duration } from 'luxon'
import UnitNotificationGroup from '../UnitNotification/UnitNotificationGroup';
import { CAMERA_PLATAFORM } from '@/store/catalogs/CAMERA_PLATAFORM';
import { SearchResult } from '@/store/interfaces/hikvision.interface';
import { ICameraPlataform } from '@/store/interfaces/CameraPlataform';
import Vue from 'vue';
import { REPORT_ITEM_IGNITION_STATE } from '@/store/catalogs/REPORT_ITEM_IGNITION_STATE';

export default abstract class ReportItemData<RIType, RIData> implements ReportItem {
  // Identificador del ReportItem
  id_report_item: number
  id_report_group: number
  id_group: number
  // Datos si existe el id de la unidad en wialon
  gp_wialon_id: number
  ri_wialon_id: string
  unit_timer!: Timer
  unit_time_report: string
  unit_item!: RIType // Instancia de wialon de la unidad
  // Datos si existe el id del respaldo de la unidad en wialon
  ri_back_wialon_id: string
  ri_back2_wialon_id: string

  ri_camera: any[]
  ri_camera_plataform: ICameraPlataform[]
  ri_order: number
  back_rid: ReportItemData<unknown, unknown> | undefined
  // back_unit_timer!: Timer
  // back_unit_time_report: string
  // back_unit_item: RIType | null | undefined // Instancia del respaldo de wialon de la unidad
  // Otros datos relevantes
  ri_unit: string
  ri_type: PLATAFORM_TYPE
  last_data: {
    MESSAGE: string
    STATUS: string
    DESTINATION: ReportDestinationData
  }

  // Valor de ignicion de la unidad
  ignition_value: number
  ignition_interval_id: number
  ignition_timeout_id: number
  last_time_report: number
  ri_config: ReportItemConfig
  ri_properties: any;

  // Last Message from GPS ot unit
  // @ts-ignore
  last_message: RIData & { pos: { x: number; y: number; c: number } }

  // Array de geocercas sobre las cuales se a seleccioado en la unidad
  location: { onZone: boolean; keyListener: string, atmReportListener: string }

  ubication: string
  ubication_interval_id: number

  messages: {
    isReporting: boolean
    timertInvervalId: number,
    eventsIntervalId: number,
    keyListener: string,
    backTimerIntervalId: number,
    backKeyListener: string
    isNotWithIgnitionActive: boolean
  }

  gps_low_battery: boolean
  unit_low_battery: boolean
  disabled: boolean

  currCoordinates: any
  lastCoordinates: any

  showOnMap: boolean

  abstract bateryFlag: string

  // ID unico para la unidad
  unitUniqueId: string
  // ID unico para el respaldo de la unidad
  backUnitUniqueId: string | null

  constructor (reportItem: ReportItem, properties: ReportItemProperties<RIType>, options = { initObservers: true }) {
    if (!properties.unitItem) {
      console.log(`Sin accesos a la unidad ${reportItem.ri_unit}`);
      store.commit('sys/ADD_LOG', { title: 'UNIT_INACCESSIBLE', color: 'warning', message: `Sin accesos a datos la unidad ${reportItem.ri_unit}`, payload: reportItem }, { root: true })
    }

    // --------------- Inicializando campos -----------------------------------
    // @ts-ignore
    this.unit_item = properties.unitItem
    // this.back_unit_item = properties.backItem

    this.id_report_item = reportItem.id_report_item
    this.id_report_group = properties.id_report_group
    this.id_group = properties.id_group

    this.gp_wialon_id = properties.gp_wialon_id
    this.ri_wialon_id = reportItem.ri_wialon_id
    // @ts-ignore
    this.unit_time_report = ''

    this.ri_back_wialon_id = reportItem.ri_back_wialon_id
    this.back_rid = properties?.backRID

    this.ri_back2_wialon_id = reportItem.ri_back2_wialon_id
    // @ts-ignore
    this.back_unit_time_report = ''

    this.ri_unit = reportItem.ri_unit
    this.ri_type = properties.type
    this.last_data = reportItem.last_data
    // @ts-ignore
    this.last_message = {
      pos: {
        x: 0, // Longitud
        y: 0, // Latitud
        c: 0 // Direccion
      }
    }

    this.messages = {
      isReporting: true,
      timertInvervalId: -1,
      eventsIntervalId: -1,
      keyListener: '',
      backTimerIntervalId: -1,
      backKeyListener: '',
      isNotWithIgnitionActive: false
    }
    this.location = {
      onZone: true,
      keyListener: '',
      atmReportListener: ''
    }
    this.gps_low_battery = false
    this.unit_low_battery = false
    this.disabled = false
    this.ubication = 'DESCONOCIDA'
    this.ubication_interval_id = 0
    // @ts-ignore
    this.ignition_value = -1
    this.ignition_interval_id = -1
    this.ignition_timeout_id = -1
    this.last_time_report = 0
    this.ri_config = getReportItemConfig(reportItem.ri_config)
    this.ri_order = reportItem.ri_order
    this.ri_properties = reportItem.ri_properties
    this.ri_camera = reportItem.ri_camera
    this.ri_camera_plataform = reportItem.ri_camera_plataform
    this.currCoordinates = null
    this.lastCoordinates = null
    this.showOnMap = false

    this.ri_unit = this.getUnitName()
    this.unitUniqueId = this.getUnitUniqueId()
    this.backUnitUniqueId = this.getUnitBackUniqueId()

    if (options.initObservers) {
      this.unit_timer = new Timer()

      this.susbscribeToUnitMessages()

      this.gps_low_battery = this.isBatteryGPSLevelLow()
      this.unit_low_battery = this.isBatteryUnitLevelLow()

      this.initIntervalIgnitionObserver()

      this.onUnitDataChanged()

      this.initUnitMessageObserver()
    }
  }

  onLastDataChanged () {
    this.makeLocationRequest()
    if (this.back_rid) {
      this.back_rid.last_data = this.last_data;
      this.back_rid.makeLocationRequest()
    }
  }

  async checkIfIsOnSignalLostGeofences () {
    // @ts-ignore
    const notEvents: UnitNotificationGroup[] = store.state.console.consoleNotificationsEvents

    const consoleGeofences = this.getCurrConsoleGeofences()
    const coors = this.getCurrentCoordinatesRaw()
    const plataformGeofences = await this.getCurrPlataformGeofences(coors)

    const geofences: Geofence[] = [...consoleGeofences, ...plataformGeofences]

    // @ts-ignore
    let geofence: Geofence = null

    for (let i = 0; i < geofences.length; i++) {
      geofence = geofences[i];

      const haveNotEvent = notEvents.some(eve => eve.firstNotification.resourceNotification?.geofence?.id === geofence.id &&
        eve.firstNotification.resourceNotification?.nid === CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_SHOW_ON_SIGNAL_LOST &&
        eve.firstNotification.resourceNotification.unit === parseInt(this.ri_wialon_id)
      )

      if (haveNotEvent) return;

      const args = await store.dispatch('console/getNotificationArgs', geofence.n)

      if (!args._.length) return;

      const sosl = Boolean(args.sosl)

      // Lista de argumentos
      if (sosl) {
        const tm = parseInt(args.tm || '0')
        // console.log('Args time: ', args.tm, ' - ', tm)
        const openProtocole = Boolean(args.op)
        const n = args._.join(' ')

        const milliseconds = Duration.fromObject({ minutes: tm }).as('milliseconds')
        // console.log('Millis: ', milliseconds)
        store.dispatch('console/addEventNotification', {
          resNotification: {
            geofence,
            pType: this.ri_type,
            unitData: this.unit_item,
            blink: 0,
            color: geofence.c,
            f: 0,
            name: `Notificacion de perdida de señal en geocerca ${n}`,
            nid: CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_SHOW_ON_SIGNAL_LOST,
            p: {},
            rt: 0,
            t: (Date.now() / 1000),
            tp: '',
            txt: geofence.d,
            unit: parseInt(this.ri_wialon_id),
            url: '/audio/alert.mp3',
            x: this.last_message?.pos?.x ?? 'Desconocido',
            y: this.last_message?.pos?.y ?? 'Desconocido'
          },
          milliseconds,
          openProtocole
        })
      }
    }
  }

  public get cameraPlataformWithData () {
    return this.ri_camera_plataform.map((cam) => {
      const camera_plataform = cam

      if (cam.cpf_type === CAMERA_PLATAFORM.HIKVISION) {
        const EhomeID = cam?.cpt_properties?.EhomeID
        // @ts-ignore
        const matchEntity = store.state.hikvision.SearchResult ? (store.state.hikvision.SearchResult as SearchResult).MatchList.find((obj) => obj.Device.EhomeParams.EhomeID === EhomeID) : undefined
        camera_plataform.device = matchEntity?.Device
        camera_plataform.online = camera_plataform?.device?.devStatus === 'online'
      }

      return camera_plataform
    })
  }

  getResPlataformGeofences (): Geofence[] {
    return store.getters['geofences/geofencesItems'](this.ri_type, { id_wialon: this.gp_wialon_id, id_group: this.id_group })
  }

  abstract unsusbscribeToUnitMessages(): void

  abstract susbscribeToUnitMessages(): void

  setConfig (ri_config: ReportItemConfig) {
    this.ri_config = ri_config
    this.onUnitDataChanged()
    if (this.back_rid) this.back_rid.setConfig(ri_config)
  }

  public async onUnitDataChanged () {
    // console.log(this.ri_unit, 'onUnitDataChanged')
    this.last_time_report = getSecondsDiffFromNow(this.getLastMessageTimeInMillisecons())

    if (this.messages.keyListener) {
      this.unit_timer.reset()
      this.unit_timer.stop()
      this.unit_timer.start({
        startValues: {
          seconds: this.last_time_report
        }
      })
      this.unit_time_report = timeFormating(this.unit_timer)
    }

    // @ts-ignore
    const isGroupNotificationsActive: boolean = await store.dispatch('console/isSubscribedToGroup', this.id_group)

    if (isGroupNotificationsActive) {
      if (this.ri_config.unit_notification_max_speed > 0 && this.speed() > this.ri_config.unit_notification_max_speed) {
        this.dispatchEventNotification(CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_MAX_SPEED)
      }

      if (!this.gps_low_battery && this.isBatteryGPSLevelLow()) {
        this.dispatchEventNotification(CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_LOW_GPS_BATERY)
      }

      if (!this.unit_low_battery && this.isBatteryUnitLevelLow()) {
        this.dispatchEventNotification(CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_LOW_UNIT_BATERY)
      }

      if (this.ri_config.unit_notification_alert_without_ignition > 0 && this.isLastTimeReportLimit) {
        this.dispatchEventNotification(CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_NOT_REPORTING_ON_IGNITION_FALSE)
      }
    }

    this.gps_low_battery = this.isBatteryGPSLevelLow()
    this.unit_low_battery = this.isBatteryUnitLevelLow()
  }

  /**
   * Checks if the last time report exceeds the configured alert limit without ignition.
   *
   * @returns {boolean} True if the last time report is greater than the configured
   * notification alert without ignition and the alert is greater than zero; otherwise, false.
   */
  get isLastTimeReportLimit (): boolean {
    if (this.last_time_report > this.ri_config.unit_notification_alert_without_ignition && this.ri_config.unit_notification_alert_without_ignition > 0) return true;
    return false
  }

  public get getFormatedTimeWithoutReporting () {
    const iso = DateTime.fromSeconds(this.getLastMessageTimeInMillisecons()).toISO()
    const time = formatLuxonDate(iso)
    return time
  }

  isBatteryUnitLevelLow () {
    return this.batteryUnitLevel() !== -1 && this.ri_config.unit_notification_low_unit_battery !== -1 && this.ri_config.unit_notification_low_unit_battery > this.batteryUnitLevel()
  }

  isBatteryGPSLevelLow () {
    return this.batteryGPSLevel() !== -1 && this.ri_config.unit_notification_low_gps_battery !== -1 && this.ri_config.unit_notification_low_gps_battery > this.batteryGPSLevel()
  }

  abstract getUnitName(): string

  get lastDataDestinationsNames () {
    return this.last_data.DESTINATION.geofences.map(obj => obj.n).concat((this.last_data?.DESTINATION?.route_destinations || []).map(obj => obj.rd_name)).join(', ')
  }

  get rIPropertiesVariables () {
    const names = Object.entries(this.ri_properties).map(ppt => ({
      wv_name: ppt[0],
      wv_description: 'Propiedad personalizada'
    }))
    return names
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getVariables (varNames: string[]) {
    const unit_plataform_id = () => this.ri_wialon_id
    const unit_name = () => this.ri_unit
    const current_destinations = () => {
      const geofences = this.last_data?.DESTINATION?.geofences ? this.last_data.DESTINATION.geofences.map(geo => geo.n).join(', ') : []
      const route_destinations = this.last_data?.DESTINATION?.route_destinations ? (this.last_data?.DESTINATION?.route_destinations || []).map(rd => rd.rd_name).join(', ') : []
      if (this.last_data?.DESTINATION?.geofences?.length === 0 && this.last_data?.DESTINATION?.route_destinations?.length === 0) {
        return 'SIN DESTINOS'
      }

      return `${geofences}, ${route_destinations}`
    }
    const google_link = () => this.getGoogleLink()
    const coordinates = () => this.getCoordinates()
    const current_drivers = () => {
      if (!this.drivers.length) return 'SIN CONDUCTORES';
      return `${this.drivers.map(d => `${d.n} ${d.p}`).join(', ')}`
    }
    const ubication = async () => await trycatch(() => this.getUbication(), 'NO DISPONIBLE')
    const unit_state = () => {
      return this.speed() !== undefined
        ? this.speed() !== 0
          ? 'En movimiento'
          : 'Detenida'
        : 'Desconocido'
    }
    const max_speed_limit = () => this.ri_config.unit_notification_max_speed
    const notification_alert = () => this.ri_config.unit_notification_alert
    const notification_low_gps_battery = () => this.ri_config.unit_notification_low_gps_battery
    const notification_low_unit_battery = () => this.ri_config.unit_notification_low_unit_battery
    const gps_battery = () => this.batteryGPSLevel()
    const unit_battery = () => this.batteryUnitLevel()
    const unit_time_report = () => this.stoppedTime()
    const back_unit_time_report = () => this.back_rid?.unit_time_report ?? 'NO DISPONBLE'
    const speed = () => this.speed()
    const curr_matches_geofences = async () => {
      const geofences: Geofence[] = await store.dispatch('maps/getGeofencesByReportItem', { report_item: this })
      const matches: Geofence[] = await store.dispatch('maps/getMatchesGeofecesByPayload', {
        lat: this.last_message.pos.y,
        lng: this.last_message.pos.x,
        geofences
      })
      return matches.map(geo => geo.n).join(', ')
    }
    const curr_matches_route_destinations = async () => {
      const routeDestinations: RouteDestination[] = await store.dispatch('maps/getRouteDestinationByReportItem', { report_item: this })
      const matches: RouteDestination[] = await store.dispatch('maps/getMatchesRDByPayload', {
        lat: this.last_message.pos.y,
        lng: this.last_message.pos.x,
        routeDestinations
      })
      return matches.map(rd => rd.rd_name).join(', ')
    }

    const errors: { [key: string]: any } = {}
    const varFunctions = {
      unit_plataform_id,
      unit_name,
      current_destinations,
      google_link,
      coordinates,
      current_drivers,
      ubication,
      unit_state,
      max_speed_limit,
      notification_alert,
      notification_low_gps_battery,
      notification_low_unit_battery,
      gps_battery,
      unit_battery,
      unit_time_report,
      back_unit_time_report,
      speed,
      curr_matches_geofences,
      curr_matches_route_destinations
    }

    const varEntries = await Promise.all(
      varNames.map(
        // @ts-ignore
        async name => [name, varFunctions[name]
        // @ts-ignore
          ? await trycatch(() => varFunctions[name](), '', {
                    onError: (e) => {
                      errors[name] = serializeError(e)
                    }
                  })
          : '']
      )
    )
    const variables = Object.fromEntries(varEntries)

    return { variables, errors }
  }

  async copyWMessage ({ message }: { message: string }) {
    try {
      store.dispatch('sys/showNotificationMessage', {
        title: 'Copiando mensaje',
        color: 'warning'
      })
      const arrVariables = extract(['{', '}'])(message)
      const { variables, errors } = await this.getVariables(arrVariables)
      const hasErrors = Object.keys(errors).length > 0
      const ms = format(message, variables)
      await store.dispatch('sys/copyMessage', {
        message: ms,
        messageCalback: () => {
          if (hasErrors) {
            store.dispatch('sys/showNotificationMessage', {
              title: 'Mensaje copiado con errores',
              color: 'warning',
              duration: 7000
            })
            store.commit('sys/ADD_LOG', {
              title: 'REPORT_ITEM_COPY_MESSAGE_WITH_ERRORS',
              color: 'warning',
              message: '',
              payload: {
                ri: JSON.parse(JSON.stringify(this)),
                variables,
                errors,
                message
              }
            })
          } else {
            store.dispatch('sys/showNotificationMessage', {
              title: 'Mensaje copiado',
              color: 'success'
            })
            store.commit('sys/ADD_LOG', {
              title: 'REPORT_ITEM_COPY_MESSAGE',
              color: 'info',
              message: '',
              payload: {
                ri: JSON.parse(JSON.stringify(this)),
                variables,
                errors,
                message
              }
            })
          }
        }

      })
    } catch (error) {
      store.commit('sys/ADD_LOG', { title: 'REPORT_ITEM_COPY_MESSAGE', color: 'error', message: error, payload: serializeError(error) })
      console.error(error)
      store.dispatch('sys/showNotificationMessage', {
        title: 'Error al intentar copiar el mensaje',
        color: 'error'
      })
    }
  }

  onUnitReportSecondUpdated () {
    if (this.messages.isNotWithIgnitionActive) {
      if (
        this.ri_config.unit_notification_alert !== -1 &&
        this.ignition_value === REPORT_ITEM_IGNITION_STATE.ON &&
        this.unit_timer.getTotalTimeValues().seconds >= this.ri_config.unit_notification_alert &&
        this.messages.isReporting
      ) {
        this.messages.isReporting = false
        this.dispatchEventNotification(CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_NOT_REPORTING_ON_IGNITION_TRUE)
        store.dispatch('sys/showNavigatorMessage', {
          title: `${this.ri_unit}`,
          body: `Lleva ${this.ri_config.unit_notification_alert} segundos sin reportar`,
          icon: this.getIcon()
        })
      }

      if (
        (this.ignition_value !== REPORT_ITEM_IGNITION_STATE.ON ||
          this.unit_timer.getTotalTimeValues().seconds < this.ri_config.unit_notification_alert) &&
        !this.messages.isReporting
      ) {
        this.messages.isReporting = true
      }
    }
  }

  initUnitMessageObserver () {
    if (this.messages.keyListener) return; // Si ya esta iniciando, no lo reinicia y retorna
    const initDate = this.getLastMessageTimeInMillisecons()
    const unitStartAt = getSecondsDiffFromNow(initDate);

    this.unit_timer.start({
      startValues: { seconds: unitStartAt }
    });

    const timertInvervalId = setInterval(() => {
      this.unit_time_report = timeFormating(this.unit_timer);
    }, 5000);

    this.unit_timer.addEventListener('secondsUpdated', () => this.onUnitReportSecondUpdated());

    this.initBackUnitObserver();

    // @ts-ignore
    this.messages.timertInvervalId = timertInvervalId;
    // @ts-ignore
    this.messages.keyListener = 'subscribed';
  }

  stopUnitMessageObserver (): void {
    try {
      this.unit_timer.removeEventListener('secondsUpdated', () => this.onUnitReportSecondUpdated());
      clearInterval(this.messages.timertInvervalId);
      clearInterval(this.messages.eventsIntervalId);
      this.unit_time_report = '';
      this.unit_timer.reset();
      this.unit_timer.stop();
      this.messages.isReporting = true;
      this.stopBackUnitObserver();
      this.messages.keyListener = '';
    } catch (error) {
      store.commit('sys/ADD_LOG', {
        title: 'STOP_UNIT_MESSAGE_OBSERVER',
        color: 'error',
        payload: serializeError(error)
      });
      console.error(error);
    }
  }

  public initMonitoringNotificationsObservers () {
    if (this.messages.isNotWithIgnitionActive) return // Si ya esta activado, ya no lo inicia
    this.messages.isNotWithIgnitionActive = true
    const eventsIntervalId = setInterval(() => {
      if (!this.messages.isReporting) {
        this.checkIfIsOnSignalLostGeofences()
      }
    }, 60000);
    // @ts-ignore
    this.messages.eventsIntervalId = eventsIntervalId;
    this.onUnitReportSecondUpdated();
  }

  public stopMonitoringNotificationsObservers () {
    this.messages.isNotWithIgnitionActive = false
    this.messages.isReporting = true;
    if (this.messages.eventsIntervalId) clearInterval(this.messages.eventsIntervalId);
  }

  abstract getUbication(): Promise<string>

  initBackUnitObserver () {
    if (this.back_rid) this.back_rid?.initUnitMessageObserver()
  }

  stopBackUnitObserver () {
    if (this.back_rid) this.back_rid?.stopUnitMessageObserver()
  }

  async initIntervalIgnitionObserver (): Promise<void> {
    try {
      const ignState = await this.getIgnitionState()
      this.ignition_value = ignState
      const intervalID = setInterval(() => {
        const timeout_id = setTimeout(async () => {
          try {
            const ignState = await this.getIgnitionState()
            this.ignition_value = ignState
          } catch (error) {
            console.error(error)
          }
        }, 0)
        // @ts-ignore
        this.ignition_timeout_id = timeout_id
      }, 7500)
      // @ts-ignore
      this.ignition_interval_id = intervalID
    } catch (error) {
      console.error(
        `No se pudo observar el estado de ignicion de la unidad ${this.ri_unit}`,
        error
      )
    }
    if (this.back_rid) this.back_rid.initIntervalIgnitionObserver()
  }

  /**
 * Determines if the given coordinates are within the zone.
 *
 * @param message - The coordinates to check.
 * @returns A promise that resolves to a boolean indicating whether the coordinates are within the zone.
 */
  abstract isOnZone(message: {
    y: any
    x: any
  }): Promise<boolean>

  abstract initLocationObserver(): void

  /**
   * Initializes the location observer for the report item.
   * This method initializes the location observer for the current report item and also initializes the location observer for the back report item, if it exists.
   * The location observer is responsible for tracking the location of the report item and updating the necessary data.
   */

  public initRILocationObserver () {
    this.initLocationObserver()
    if (this.back_rid) this.back_rid.initLocationObserver()
  }

  abstract stopLocationObserver(): void

  public stopRILocationObserver () {
    this.stopLocationObserver()
    if (this.back_rid) this.back_rid.stopLocationObserver()
  }

  abstract getCurrPlataformGeofencesIds(message?: { y: any; x: any }): Promise<number[]>

  getCurrConsoleGeofencesIds () {
    const console_geofences = this.getResPlataformGeofences().filter(geo => geo.pType === PLATAFORM_TYPE.CONSOLA)
    if (!this.last_message || !this.last_message.pos) return []
    // @ts-ignore
    const geofences_ids = console_geofences.filter(geo => isOnGeofence(this.last_message.pos.y, this.last_message.pos.x, geo)).map(geo => geo.id)

    return geofences_ids
  }

  async getCurrPlataformGeofences (message: { y: any; x: any }) {
    const plataformGeofencesIds = await this.getCurrPlataformGeofencesIds(message)
    if (!plataformGeofencesIds.length) return []
    // @ts-ignore
    const resGeofences = store.state.geofences.geofences.find((rGeofence) => {
      // @ts-ignore
      return rGeofence.id === parseInt(this.gp_wialon_id) && rGeofence.pType === this.ri_type
    })

    if (!resGeofences) return [];

    const currPlataformGeofences = resGeofences.auxGeofences.filter((geo: { id: number; }) => plataformGeofencesIds.includes(geo.id))

    return currPlataformGeofences
  }

  getCurrConsoleGeofences () {
    const consoleGeofencesIds = this.getCurrConsoleGeofencesIds()

    if (!consoleGeofencesIds.length) return []
    // @ts-ignore
    const consoleGeofences: Geofence[] = consoleGeofencesIds.length ? (store.state.geofences.geofences.find(rGeofence => (rGeofence.id === this.id_group && rGeofence.pType === PLATAFORM_TYPE.CONSOLA))?.auxGeofences || []) : []

    const currConsoleGeofences = consoleGeofences.filter(geo => consoleGeofencesIds.includes(geo.id))

    return currConsoleGeofences
  }

  isOnZoneConsoleGeofences (coors: { y: any; x: any }) {
    // @ts-ignore
    const routesDestinations: { id_group: number; id_plataform: number; route_destinations: RouteDestination[]; }[] = store.state.geofences.routesDestinationsGeofences.filter(
      // @ts-ignore
      rd => rd.id_plataform === this.ri_type && rd.id_group === this.id_group
    ) || []

    const rd_geofences = routesDestinations.length ? routesDestinations[0].route_destinations.map(rd => rd.rd_geofences).flat().filter(g => g?.pType === PLATAFORM_TYPE.CONSOLA).map(g => g.id) : []
    const destinations_ids = this.last_data.DESTINATION.geofences.filter(g => g.pType === PLATAFORM_TYPE.CONSOLA).map(g => g.id)
    const geofences_ids = [...new Set([...destinations_ids, ...rd_geofences])]

    if (geofences_ids.length) {
      // @ts-ignore
      const console_geofences: Geofence[] = store.state.geofences.geofences.find(rGeofence => (rGeofence.id === this.id_group && rGeofence.pType === PLATAFORM_TYPE.CONSOLA))?.auxGeofences || []
      if (console_geofences.length) {
        const geofences = console_geofences.filter(g => geofences_ids.includes(g.id))
        // @ts-ignore
        const isOnGeofences = geofences.some(geo => isOnGeofence(coors.x, coors.y, geo))
        if (isOnGeofences) return true
      }
    }
    return false
  }

  async makeLocationRequest (coordinates: { x: number, y: number } = this.last_message.pos) {
    if ((this.last_data?.DESTINATION?.geofences?.length || this.last_data?.DESTINATION?.route_destinations?.length) && this.location.keyListener && coordinates) {
      const routeDestinationsNames = this.last_data?.DESTINATION?.route_destinations ? (this.last_data?.DESTINATION?.route_destinations || []).map(rd => rd.rd_name).join(', ') : []
      const geofencesNames = this.last_data?.DESTINATION?.geofences ? this.last_data.DESTINATION.geofences.map(geo => geo.n).join(', ') : []
      const currentDestinations = `${geofencesNames}, ${routeDestinationsNames}`
      // @ts-ignore
      const onZone = await this.isOnZone(coordinates)
      if (this.location.onZone && !onZone) {
        store.dispatch('console/addNotification', {
          pType: this.ri_type,
          unitData: this.unit_item,
          blink: 0,
          color: '#AB47BC',
          f: 0,
          name: `Unidad ${this.ri_unit} abandona destinos`,
          nid: CONSOLE_NOTIFICATIONS.UNIT_DESTINATION_LEAVE,
          p: {},
          rt: 0,
          t: (Date.now() / 1000),
          tp: '',
          txt: `La unidad ${this.ri_unit} abandono los destinos ${currentDestinations}`,
          unit: parseInt(this.ri_wialon_id),
          url: '/audio/alert.mp3',
          x: coordinates.x,
          y: coordinates.y
        })
      }

      if (!(this.location.onZone) && onZone) {
        store.dispatch('console/addNotification', {
          pType: this.ri_type,
          unitData: this.unit_item,
          blink: 0,
          color: '#f0b811',
          f: 0,
          name: `Unidad ${this.ri_unit} reintegra a sus zonas`,
          nid: CONSOLE_NOTIFICATIONS.UNIT_DESTINATION_RETURN,
          p: {},
          rt: 0,
          t: (Date.now() / 1000),
          tp: '',
          txt: `La unidad ${this.ri_unit} reintegra a sus zonas ${currentDestinations}`,
          unit: parseInt(this.ri_wialon_id),
          url: '/audio/success.wav',
          x: coordinates.x,
          y: coordinates.y
        })
      }

      this.location.onZone = onZone;
    }
  }

  setDestinationData (geofences: any[]) {
    this.last_data.DESTINATION.geofences = geofences
  }

  sendDestinationData () {
    store.dispatch('reports/changeReportItemDestinations', {
      geofences: this.last_data.DESTINATION.geofences,
      id_report_item: this.id_report_item,
      id_report_group: this.id_report_group
    })
  }

  setRoutesDestinations (routes_destinations: any[]) {
    this.last_data.DESTINATION.route_destinations = routes_destinations
  }

  sendRoutesDestinations () {
    const DESTINATION = this.last_data.DESTINATION
    const reportItemPayload = {
      id_report_group: this.id_report_group,
      id_report_item: this.id_report_item,
      last_data: { DESTINATION },
      additional_data: {
        drivers: this.drivers
      }
    }
    store.dispatch('reportEdit', reportItemPayload)
  }

  sendGeofenceNotifications (
    geofences: Array<{ id: number, n: string, notifications: Array<{ id: number, name: string }>, pType: number }>,
    id_report_group: number,
    id_report_item: number) {
    store.dispatch('reports/sendGeofenceNotifications', {
      geofences,
      id_report_group,
      id_report_item
    })
  }

  abstract getIcon(): string

  stopIntervalIgnitionObserver (): void {
    this.ignition_value = -1
    clearTimeout(this.ignition_timeout_id)
    clearInterval(this.ignition_interval_id)
    if (this.back_rid) this.back_rid.stopIntervalIgnitionObserver()
  }

  async sendMessageItem (e: { target: { value: string } }) {
    await store.dispatch('reports/sendReportItemMessage', {
      id_report_item: this.id_report_item,
      // @ts-ignore
      ri_message: e.target.value.replace(/(^\s+|\s+$)/g, ''),
      id_report_group: this.id_report_group
    })
  }

  async sendStatus (description: string, isVaruna: boolean) {
    this.last_data.STATUS = description
    const reportItemPayload: ReportItemPayload = {
      id_report_group: this.id_report_group,
      id_report_item: this.id_report_item,
      last_data: {
        STATUS: this.last_data.STATUS,
        VARUNA: isVaruna
      },
      additional_data: {
        drivers: this.drivers
      }
    }
    await store.dispatch('reports/reportEdit', reportItemPayload)
  }

  getUnitUniqueId (): string {
    return `unit-${this.id_report_item}${this.gp_wialon_id || ''}${this.ri_type}`
  }

  getUnitBackUniqueId (): string | null {
    if (!this.ri_back_wialon_id) return null
    return `backup-unit-${this.id_report_item}${this.ri_back_wialon_id || ''}${this.ri_type}`
  }

  // 0 Off - 1 On
  abstract getIgnitionState(): Promise<REPORT_ITEM_IGNITION_STATE>

  abstract getCoordinates(): string

  abstract speed(): number

  abstract stoppedTime(): string | number

  abstract getGoogleLink(): string

  abstract getCurrentCoordinatesRaw(): {
    x: any
    y: any
  }

  abstract getLinkCamera(): Promise<any>
  abstract getHistoryVideo(config?: any): Promise<string>

  setIgnitionState (state: number): void {
    this.ignition_value = state
  }

  getDrivers () {
    const plataform_drivers = this.getResDrivers()
    const console_drivers = this.getConsoleDrivers()
    return Vue.observable([...plataform_drivers, ...console_drivers])
  }

  get drivers (): Driver[] {
    return this.getDrivers()
  }

  abstract getResDrivers(): Driver[]

  getConsoleDrivers (): Driver[] {
    // @ts-ignore
    const driversPlataform = store.state?.drivers?.drivers[PLATAFORM_TYPE.CONSOLA]
    if (driversPlataform) {
      const driversGroup = driversPlataform[this.id_group]
      if (driversGroup) {
        const drivers: Driver[] = driversGroup[this.id_report_item]
        if (drivers) {
          return drivers
        }
      }
    }

    return []
  }

  get isStopped () {
    return this.speed() <= 0
  }

  get cource () {
    return this.last_message.pos.c
  }

  abstract getSensors(): Promise<Sensors[]>

  abstract getCommandList(): ICommand[]

  abstract executeCommand(payload: any): Promise<void>

  abstract batteryGPSLevel(): number

  abstract batteryUnitLevel(): number

  public getCamaraPlataformsByPlataformType (cType: CAMERA_PLATAFORM) {
    return this.ri_camera_plataform.filter((cam) => cam.cpf_type === cType)
  }

  public getPlataformName () {
    switch (this.ri_type) {
      case PLATAFORM_TYPE.WIALON: {
        return 'WIALON'
      }
      case PLATAFORM_TYPE.MAPON: {
        return 'MAPON'
      }
      case PLATAFORM_TYPE.TRACKSOLID: {
        return 'TRACK SOLID'
      }
      default:
        return 'INDEFINIDO'
    }
  }

  public hasPlataformId (id: string | number) {
    return `${this.ri_wialon_id}` === `${id}` || `${this.ri_back_wialon_id}` === `${id}` || `${this.ri_back2_wialon_id}` === `${id}`
  }

  abstract getLastMessageTimeInMillisecons(): number

  setCoordinates (newCoordinates: { x: number, y: number }) {
    if (this.currCoordinates === null) {
      this.currCoordinates = newCoordinates
    } else {
      this.lastCoordinates = this.currCoordinates
      this.currCoordinates = newCoordinates
    }
  }

  public stopAllUnitEvents () {
    this.unsusbscribeToUnitMessages()
    this.stopRILocationObserver()
    this.stopUnitMessageObserver()
    this.stopIntervalIgnitionObserver()
    this.stopBackUnitObserver()
  }

  public dispatchEventNotification (cn: CONSOLE_NOTIFICATIONS, payload = {} as any) {
    switch (cn) {
      case CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_MAX_SPEED: {
        store.dispatch('console/addNotification', {
          pType: this.ri_type,
          unitData: this.unit_item,
          blink: 0,
          color: '#00a4f0',
          f: 0,
          name: `Limite de velocidad de ${this.ri_unit}`,
          nid: CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_MAX_SPEED,
          p: {},
          rt: 0,
          t: (Date.now() / 1000),
          tp: '',
          txt: `La unidad ${this.ri_unit} excedio el limite de velocidad de ${this.ri_config.unit_notification_max_speed} km/h llendo a una velocidad de ${this.speed()} km/h`,
          unit: parseInt(this.ri_wialon_id),
          url: '/audio/alert.mp3',
          x: this.last_message.pos.x,
          y: this.last_message.pos.y
        })
        break;
      }
      case CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_LOW_GPS_BATERY: {
        store.dispatch('console/addNotification', {
          pType: this.ri_type,
          unitData: this.unit_item,
          blink: 0,
          color: '#b82334',
          f: 0,
          name: `Unidad ${this.ri_unit} con bateria de gps baja`,
          nid: CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_LOW_GPS_BATERY,
          p: {},
          rt: 0,
          t: (Date.now() / 1000),
          tp: '',
          txt: `La unidad ${this.ri_unit} cuenta un nivel de bateria baja del gps del ${this.batteryGPSLevel()}${this.bateryFlag}`,
          unit: parseInt(this.ri_wialon_id),
          url: '/audio/alert.mp3',
          x: this.last_message.pos.x,
          y: this.last_message.pos.y
        })
        break;
      }
      case CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_LOW_UNIT_BATERY: {
        store.dispatch('console/addNotification', {
          pType: this.ri_type,
          unitData: this.unit_item,
          blink: 0,
          color: '#b82334',
          f: 0,
          name: `Unidad ${this.ri_unit} con bateria de la unidad baja`,
          nid: CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_LOW_UNIT_BATERY,
          p: {},
          rt: 0,
          t: (Date.now() / 1000),
          tp: '',
          txt: `La unidad ${this.ri_unit} cuenta un nivel de bateria baja de la unidad del ${this.batteryUnitLevel()}${this.bateryFlag}`,
          unit: parseInt(this.ri_wialon_id),
          url: '/audio/alert.mp3',
          x: this.last_message.pos.x,
          y: this.last_message.pos.y
        })
        break;
      }
      case CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_NOT_REPORTING_ON_IGNITION_TRUE: {
        store.dispatch('console/addNotification', {
          pType: this.ri_type,
          unitData: this.unit_item,
          blink: 0,
          color: '#bfb902',
          f: 0,
          name: `Unidad ${this.ri_unit} no reportando, con ignición`,
          nid: CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_NOT_REPORTING_ON_IGNITION_TRUE,
          p: {},
          rt: 0,
          t: (Date.now() / 1000),
          tp: '',
          txt: `${this.ri_unit} lleva ${this.ri_config.unit_notification_alert} segundos sin reportar`,
          unit: parseInt(this.ri_wialon_id),
          url: '/audio/alert.mp3',
          x: this.last_message?.pos?.x ?? 'Desconocido',
          y: this.last_message?.pos?.y ?? 'Desconocido'
        })
        break;
      }
      case CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_NOT_REPORTING_ON_IGNITION_FALSE: {
        const time = this.getFormatedTimeWithoutReporting

        store.dispatch('console/addNotification', {
          pType: this.ri_type,
          unitData: this.unit_item,
          blink: 0,
          color: '#bfb902',
          f: 0,
          name: `Unidad ${this.ri_unit} no reportando, sin ignición`,
          nid: CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_NOT_REPORTING_ON_IGNITION_TRUE,
          p: {},
          rt: 0,
          t: (Date.now() / 1000),
          tp: '',
          txt: `${this.ri_unit} lleva ${time} sin reportar`,
          unit: parseInt(this.ri_wialon_id),
          url: '/audio/alert.mp3',
          x: this.last_message?.pos?.x ?? 'Desconocido',
          y: this.last_message?.pos?.y ?? 'Desconocido'
        })
        break;
      }
      case CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_SHOW_ON_SIGNAL_LOST: {
        store.dispatch('console/addNotification', {
          pType: this.ri_type,
          unitData: this.unit_item,
          blink: 0,
          color: payload.c,
          f: 0,
          name: `Notificacion de perdida de señal en geocerca ${payload.n}`,
          nid: CONSOLE_NOTIFICATIONS.UNIT_NOTIFICATION_SHOW_ON_SIGNAL_LOST,
          p: {},
          rt: 0,
          t: (Date.now() / 1000),
          tp: '',
          txt: payload.d,
          unit: parseInt(this.ri_wialon_id),
          url: '/audio/alert.mp3',
          x: this.last_message?.pos?.x ?? 'Desconocido',
          y: this.last_message?.pos?.y ?? 'Desconocido'
        })
        break;
      }
    }
  }
}

export interface ReportItemProperties<RIType> {
  id_report_group: number
  id_group: number
  gp_wialon_id: number
  type: PLATAFORM_TYPE
  unitItem?: RIType
  // @ts-ignore
  backRID?: ReportItemData<unknown, unknown>
}

export interface GeofenceAux {
  id: number,
  n: string,
  notifications: Array<{ id: number, name: string, default?: boolean }>,
  gType: any
}
